import type { MaybeRef } from 'vue'
import type { Schemas } from '#shopware'
import { ProfessionalCustomerGroupId } from '~/types/general'

export const useCustomer = (
  replaceUser?: MaybeRef<Schemas['Customer'] | undefined>
) => {
  const { user } = useUser()

  const isProfessional = computed(() => {
    const customer = toValue(replaceUser) || user.value
    return customer?.groupId === ProfessionalCustomerGroupId
  })

  return {
    isProfessional,
  }
}
